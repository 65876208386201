import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "cg-toast";

// Helper functions imports
import { ROUTES_PATHS } from "./helpers/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import { handleResize } from "./helpers/constants/utlis";
import Loader from "./components/Loader";

// Custom components imports
const Home = lazy(() => import("./pages/Home"));
const Offers = lazy(() => import("./pages/OfferListing"));
const OfferDetails = lazy(() => import("./pages/OfferDetails"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Profile = lazy(() => import("./pages/Profile"));
const CreditCard = lazy(() => import("./pages/CreditCard"));
const PrersonalLoan = lazy(() => import("./pages/PersonalLoan"));

const App = () => {
  const { token = "" } = useSelector((state: RootState) => state?.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <div className="App">
      <Routes>
        <Route
          path={ROUTES_PATHS.HOME}
          element={
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={ROUTES_PATHS.OFFERS_DETAILS}
          element={
            <Suspense fallback={<Loader />}>
              <OfferDetails />
            </Suspense>
          }
        />
        <Route
          path={ROUTES_PATHS.PRIVACY_POLICY}
          element={
            <Suspense fallback={<Loader />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path={ROUTES_PATHS.ABOUT_US}
          element={
            <Suspense fallback={<Loader />}>
              <AboutUs />
            </Suspense>
          }
        />
        <Route
          path={ROUTES_PATHS.PERSONAL_LOAN}
          element={
            <Suspense fallback={<Loader />}>
              <PrersonalLoan />
            </Suspense>
          }
        />
        <Route
          path={ROUTES_PATHS.CREDIT_CARD}
          element={
            <Suspense fallback={<Loader />}>
              <CreditCard />
            </Suspense>
          }
        />
        <Route
          path={ROUTES_PATHS.OFFERS}
          element={
            token ? (
              <Suspense fallback={<Loader />}>
                <Offers />
              </Suspense>
            ) : (
              <Navigate to={ROUTES_PATHS.HOME} replace />
            )
          }
        />
        <Route
          path={ROUTES_PATHS.PROFILE}
          element={
            token ? (
              <Suspense fallback={<Loader />}>
                <Profile />
              </Suspense>
            ) : (
              <Navigate to={ROUTES_PATHS.HOME} replace />
            )
          }
        />
      </Routes>
      <Toaster />
    </div>
  );
};
export default App;
